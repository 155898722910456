@import '../../node_modules/susy/sass/susy';
@import '../../node_modules/breakpoint-sass/stylesheets/breakpoint';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@500;900&family=Open+Sans:wght@600&display=swap');
@import '_reset';

$main-color: #364575;

html {
    font-family: 'Open Sans', 'Noto Sans TC', sans-serif;
}


.select-ui {
    box-sizing: border-box;
    position: relative;
    background-color: #fff;
    height: 40px;
    display: inline-block;
    width: 100%;
    &--inline {
        width: 120px;
        margin-right: 16px;
        &:last-child {
            margin-right: 0;
        }
    }
    &--inline-page {
        width: 300px;
        margin-right: 16px;
        &:last-child {
            margin-right: 0;
        }
    }
    &::before {
        z-index: 0;
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        -webkit-transform: translate3d(0,-50%,0);
        transform: translate3d(0,-50%,0);
        width: 0;
        height: 0;
        pointer-events: none;
        width: 40px;
        height: 40px;
        background-color: #364575;
    }
    &::after {
        z-index: 0;
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        -webkit-transform: translate3d(-14px,-10px,0) rotate(45deg);
        transform: translate3d(-14px,-8px,0) rotate(135deg);
        width: 0;
        height: 0;
        pointer-events: none;
        border-top: solid 2px #fff;
        border-right: solid 2px #fff;
        width: 10px;
        height: 10px;
    }
    select {
        outline: none;
        border: solid 1px #cad0e6;
        width: 100%;
        color: #1c2d61;
        font-weight: 500;
        border-radius: 0;
        line-height: 40px;
        height: 40px;
        box-sizing: border-box;
        padding: 0 8px;
        background: #fff;
        box-shadow: none;
        background-color: transparent;
        background-image: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 14px;
        transition: all 0.5s ease-in-out;
        &:focus {
            border-color: #364575;
            background-color: #e6e9f6;
        }
    }
}

.title-ui {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: solid 1px #eee;
    margin-bottom: 16px;
    position: relative;
    h2 {
        color: $main-color;
        font-size: 24px;
        text-align: center;
        margin-bottom: 12px;
    }
    p {
        font-size: 14px;
        color: #666;
        text-align: center;
        line-height: 1.6;
    }
    &__btn-add,
    &__btn-back {
        position: absolute;
        top: 12px;
        right: 32px;
        background-color: $main-color;
        line-height: 32px;
        height: 32px;
        display: inline-block;
        color: #fff;
        text-align: center;
        font-size: 14px;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        border: 0;
        width: 96px;
        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            text-decoration: none;
            background-color: darken($main-color,10%);
        }
    }
}

.group-ui {
    box-shadow: 0 2px 4px rgba(0,0,0,.05);
    padding: 32px;
    border: solid 1px #eee;
    margin-bottom: 24px;
    border-radius: 8px;
    box-sizing: border-box;
    &:last-child {
        margin-bottom: 0;
    }
}

.login-panel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    width: 320px;
    border-radius: 24px;
    padding: 32px;
    box-shadow: 0 4px 12px rgba(#364575,0.3);
    &__text {
        font-size: 16px;
        text-align: center;
        margin-bottom: 24px;
        color: $main-color;
    }
    &__rows {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__form {
        label {
            display: block;
            font-size: 14px;
            color: #999;
            margin-bottom: 4px;
        }
        input {
            box-sizing: border-box;
            width: 100%;
            border: solid 1px #ccc;
            padding: 0 8px;
            line-height: 40px;
            height: 38px;
            transition: border-color 0.5s ease-in-out;
            color: $main-color;
            &:focus {
                border-color: $main-color;
            }
        }
    }
    &__btn-login {
        width: 100%;
        background-color: $main-color;
        line-height: 48px;
        height: 48px;
        text-align: center;
        color: #fff;
        border: 0;
        &:hover {
            text-decoration: none;
            background-color: darken($main-color,10%);
        }
    }
}

.login-footer {
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 8px;
    text-align: right;
    font-size: 14px;
    color: #999;
    border-top: 1px solid #eee;
}

.left-panel {
    box-sizing: border-box;
    padding: 16px;
    width: 156px;
    &__logo {
        margin-bottom: 16px;
        border-bottom: 1px solid #eee;
        img {
            max-width: 100%;
            display: block;
            margin: 0;
        }
    }
    &__profile,
    &__nav {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #eee;
    }
    &__name {
        color: $main-color;
        font-size: 16px;
        margin-bottom: 8px;
        font-size: 14px;
    }
    &__btn {
        background-color: $main-color;
        line-height: 32px;
        height: 32px;
        display: block;
        color: #fff;
        text-align: center;
        font-size: 14px;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            text-decoration: none;
            background-color: darken($main-color,10%);
        }
        &--logout {
            background-color: #f14280;
            &:hover {
                background-color: darken(#f14280,10%);
            }
        }
    }
    &__authority {
        border: solid 1px $main-color;
        padding: 16px 8px 8px;
        position: relative;
        & > p {
            font-size: 14px;
            text-align: center;
            margin-bottom: 8px;
            color: $main-color;
            background-color: #fff;
            position: absolute;
            top: -6px;
            text-align: center;
            left: 50%;
            transform: translateX(-50%);
            width: 64px;
        }
        & > ul {
            li {
                margin-bottom: 8px;
                font-size: 14px;
                color: #666;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.change-psw {
    padding: 16px;
    box-sizing: border-box;
    &__text {
        font-size: 16px;
        text-align: center;
        margin-bottom: 24px;
        color: $main-color;
    }
    &__rows {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__form {
        width: 480px;
        margin: 0 auto;
        label {
            display: block;
            font-size: 14px;
            color: #999;
            margin-bottom: 4px;
        }
        input {
            box-sizing: border-box;
            width: 100%;
            border: solid 1px #ccc;
            padding: 0 8px;
            line-height: 40px;
            height: 38px;
            transition: border-color 0.5s ease-in-out;
            color: $main-color;
            &:focus {
                border-color: $main-color;
            }
        }
    }
    &__btn-submit {
        width: 100%;
        background-color: $main-color;
        line-height: 48px;
        height: 48px;
        text-align: center;
        color: #fff;
        border: 0;
        &:hover {
            background-color: darken($main-color,10%);
        }
    }
}

.logo-upload {
    padding: 16px;
    &__logo {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #eee;
    }
    &__form {
        form {
            font-size: 14px;
            color: #666;
        }
        text-align: center;
        input[type="file"] {
            font-size: 1px;
            display: inline-block;
        }
    }
    &__btn-upload {
        background-color: $main-color;
        line-height: 32px;
        height: 32px;
        display: inline-block;
        color: #fff;
        text-align: center;
        font-size: 14px;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        margin-bottom: 8px;
        border: 0;
        width: 96px;
        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            text-decoration: none;
            background-color: darken($main-color,10%);
        }
    }
    &__list {
        padding: 0 32px;
        table {
            width: 100%;
            margin: 0 auto;
            th {
                border: solid 1px #cfd1d5;
                border-bottom-width: 4px;
                padding: 20px 8px;
                color: $main-color;
                font-size: 14px;
                background-color: #fff;
                line-height: 1.6;
                transition: all 0.5s ease-in-out;
            }
            tr {
                background-color: #fefefe;
                transition: all 0.5s ease-in-out;
                &:nth-child(odd) {
                    background-color: #f9f9f9;
                }
                &:hover {
                    background-color: #e1e5f4;
                }
            }
            td {
                border: solid 1px #cfd1d5;
                padding: 12px 8px;
                text-align: center;
                color: #444;
                font-size: 16px;
                line-height: 1.6;
                transition: all 0.2s ease-in-out;
                vertical-align: middle;
                img {
                    width: 120px;
                }
            }
        }
    }
}

.account-list {
    padding: 16px;
    &__btn-add {
        position: absolute;
        top: 0;
        right: 0;
        background-color: $main-color;
        line-height: 32px;
        height: 32px;
        display: inline-block;
        color: #fff;
        text-align: center;
        font-size: 14px;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        margin-bottom: 8px;
        border: 0;
        width: 96px;
        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            text-decoration: none;
            background-color: darken($main-color,10%);
        }
    }
    &__list {
        padding: 0 32px;
        table {
            width: 100%;
            margin: 0 auto;
            th {
                border: solid 1px #cfd1d5;
                border-bottom-width: 4px;
                padding: 20px 8px;
                color: $main-color;
                font-size: 14px;
                line-height: 1.6;
                background-color: #fff;
                transition: all 0.5s ease-in-out;
            }
            tr {
                background-color: #fefefe;
                transition: all 0.5s ease-in-out;
                &:nth-child(odd) {
                    background-color: #f9f9f9;
                }
                &:hover {
                    background-color: #e1e5f4;
                }
            }
            td {
                border: solid 1px #cfd1d5;
                padding: 12px 8px;
                text-align: center;
                color: #444;
                font-size: 14px;
                transition: all 0.2s ease-in-out;
                vertical-align: middle;
                line-height: 1.6;
                a {
                    background-color: $main-color;
                    line-height: 32px;
                    height: 32px;
                    display: inline-block;
                    color: #fff;
                    text-align: center;
                    font-size: 14px;
                    text-decoration: none;
                    transition: all 0.3s ease-in-out;
                    margin-bottom: 8px;
                    border: 0;
                    width: 64px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:hover {
                        text-decoration: none;
                        background-color: darken($main-color,10%);
                    }
                }
                .status-disable,
                .status-enable {
                    width: 64px;
                    font-size: 14px;
                    display: inline-block;
                    border: solid 1px transparent;
                    height: 32px;
                    line-height: 32px;
                }
                .status-enable {
                    border-color: #6cad05;
                    color: #6cad05;
                }
                .status-disable {
                    border-color: #999;
                    color: #666;
                }
            }
        }
    }
}

.account-edit {
    padding: 16px;
    &__detail {
        width: 660px;
        margin: 0 auto;
    }
    &__rows {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
        label {
            display: block;
            font-size: 14px;
            color: #444;
            margin-bottom: 4px;
            line-height: 19px;
            &.admin {
                color: #f14280;
            }
        }
        input[type="text"],
        input[type="password"] {
            box-sizing: border-box;
            width: 100%;
            border: solid 1px #ccc;
            padding: 0 8px;
            line-height: 40px;
            height: 38px;
            transition: border-color 0.5s ease-in-out;
            color: $main-color;
            font-weight: 500;
            &:focus {
                border-color: $main-color;
                background-color: #eef0f7;
            }
        }
        input[type="checkbox"] {
            margin-right: 4px;
            display: inline-block;
            position: relative;
            top: -1px;
        }
        &>ul {
            li {
                margin-bottom: 8px;
                font-size: 14px;
                color: #666;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    &__text {
        font-size: 14px;
        color: $main-color;
        text-align: center;
        padding-bottom: 16px;
        border-bottom: 1px dashed #eee;
        margin-bottom: 16px;
        font-weight: 900;
    }
    &__account {
        font-size: 14px;
        color: $main-color;
        strong {
            font-weight: 900;
        }
    }
    &__btn-back {
        position: absolute;
        top: 0;
        right: 0;
        background-color: $main-color;
        line-height: 32px;
        height: 32px;
        display: inline-block;
        color: #fff;
        text-align: center;
        font-size: 14px;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        margin-bottom: 8px;
        border: 0;
        width: 96px;
        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            text-decoration: none;
            background-color: darken($main-color,10%);
        }
    }
    &__btn-submit {
        width: 100%;
        background-color: $main-color;
        line-height: 40px;
        height: 40px;
        display: inline-block;
        color: #fff;
        text-align: center;
        font-size: 14px;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        margin-bottom: 8px;
        border: 0;
        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            text-decoration: none;
            background-color: darken($main-color,10%);
        }
    }
    &__mesg {
        padding-top: 8px;
        color: #f14280;
        font-size: 14px;
    }
}

.measure-data {
    padding: 16px;
    &__rows {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__filter {
        width: 640px;
        margin: 0 auto;
        label {
            display: inline-block;
            font-size: 14px;
            color: #444;
            margin-bottom: 4px;
            line-height: 19px;
            &.admin {
                color: #f14280;
            }
        }
        input[type="date"] {
            box-sizing: border-box;
            width: 230px;
            border: solid 1px #ccc;
            padding: 0 8px;
            line-height: 40px;
            height: 38px;
            transition: border-color 0.5s ease-in-out;
            color: $main-color;
            font-weight: 500;
            &:focus {
                border-color: $main-color;
                background-color: #eef0f7;
            }
        }
        input[type="text"] {
            box-sizing: border-box;
            width: 280px;
            border: solid 1px #ccc;
            padding: 0 8px;
            line-height: 40px;
            height: 38px;
            transition: border-color 0.5s ease-in-out;
            color: $main-color;
            font-weight: 500;
            &:focus {
                border-color: $main-color;
                background-color: #eef0f7;
            }
        }
        input[type="checkbox"] {
            margin-right: 4px;
            display: inline-block;
            position: relative;
            top: -1px;
        }
    }
    &__btn-search {
        background-color: $main-color;
        line-height: 40px;
        height: 40px;
        display: inline-block;
        color: #fff;
        text-align: center;
        font-size: 14px;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        margin-bottom: 8px;
        border: 0;
        width: 140px;
        margin-right: 16px;
        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            text-decoration: none;
            background-color: darken($main-color,10%);
        }
    }
    &__list {
        padding: 16px 32px;
        border-top: 1px solid #eee;
        table {
            width: 100%;
            margin: 0 auto;
            th {
                border: solid 1px #cfd1d5;
                border-bottom-width: 4px;
                padding: 20px 8px;
                color: $main-color;
                font-size: 14px;
                background-color: #fff;
                transition: all 0.5s ease-in-out;
                vertical-align: middle;
                line-height: 1.6;
            }
            tr {
                background-color: #fefefe;
                transition: all 0.5s ease-in-out;
                &:nth-child(odd) {
                    background-color: #f9f9f9;
                }
                &:hover {
                    background-color: #e1e5f4;
                }
            }
            td {
                border: solid 1px #cfd1d5;
                padding: 12px 8px;
                text-align: center;
                color: #444;
                font-size: 14px;
                transition: all 0.2s ease-in-out;
                vertical-align: middle;
                line-height: 1.6;
                a {
                    background-color: $main-color;
                    line-height: 32px;
                    height: 32px;
                    display: block;
                    color: #fff;
                    text-align: center;
                    font-size: 14px;
                    text-decoration: none;
                    transition: all 0.3s ease-in-out;
                    margin-bottom: 8px;
                    border: 0;
                    width: 96px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:hover {
                        text-decoration: none;
                        background-color: darken($main-color,10%);
                    }
                }
                .status-disable,
                .status-enable {
                    width: 64px;
                    font-size: 14px;
                    display: inline-block;
                    border: solid 1px transparent;
                    height: 32px;
                    line-height: 32px;
                }
                .status-enable {
                    border-color: #6cad05;
                    color: #6cad05;
                }
                .status-disable {
                    border-color: #999;
                    color: #666;
                }
                table {
                    background-color: #fff;
                }
                tr {
                    background-color: transparent;
                    &:hover {
                        background-color: transparent;
                    }
                }
                td {
                    padding: 0;
                    background-color: transparent;
                    border: 0;
                }
            }
        }
    }
}