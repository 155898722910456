@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@500;900&family=Open+Sans:wght@600&display=swap");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

ol,
ul {
  list-style: none; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

a:focus {
  outline: 0; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

mark {
  background: yellow;
  color: black; }

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap; }

q {
  quotes: "\201C" "\201D" "\2018" "\2019"; }

small {
  font-size: 80%; }

sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em; }

sub {
  bottom: -0.25em; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
html input[type="button"] {
  -webkit-appearance: button;
  cursor: pointer; }

input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }
  input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.clearfix {
  zoom: 1; }
  .clearfix:before {
    content: "";
    display: table; }
  .clearfix:after {
    content: "";
    display: table;
    clear: both; }

html {
  font-family: sans-serif;
  line-height: 1;
  font-size: 15px; }

a {
  text-decoration: none;
  color: black; }

button,
input,
textarea,
select {
  outline: 0; }

a[href^=tel] {
  color: inherit;
  text-decoration: none; }

select::-ms-expand {
  display: none; }

html {
  font-family: 'Open Sans', 'Noto Sans TC', sans-serif; }

.select-ui {
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
  height: 40px;
  display: inline-block;
  width: 100%; }
  .select-ui--inline {
    width: 120px;
    margin-right: 16px; }
    .select-ui--inline:last-child {
      margin-right: 0; }
  .select-ui--inline-page {
    width: 300px;
    margin-right: 16px; }
    .select-ui--inline-page:last-child {
      margin-right: 0; }
  .select-ui::before {
    z-index: 0;
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
    width: 0;
    height: 0;
    pointer-events: none;
    width: 40px;
    height: 40px;
    background-color: #364575; }
  .select-ui::after {
    z-index: 0;
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate3d(-14px, -10px, 0) rotate(45deg);
    transform: translate3d(-14px, -8px, 0) rotate(135deg);
    width: 0;
    height: 0;
    pointer-events: none;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
    width: 10px;
    height: 10px; }
  .select-ui select {
    outline: none;
    border: solid 1px #cad0e6;
    width: 100%;
    color: #1c2d61;
    font-weight: 500;
    border-radius: 0;
    line-height: 40px;
    height: 40px;
    box-sizing: border-box;
    padding: 0 8px;
    background: #fff;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 14px;
    transition: all 0.5s ease-in-out; }
    .select-ui select:focus {
      border-color: #364575;
      background-color: #e6e9f6; }

.title-ui {
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: solid 1px #eee;
  margin-bottom: 16px;
  position: relative; }
  .title-ui h2 {
    color: #364575;
    font-size: 24px;
    text-align: center;
    margin-bottom: 12px; }
  .title-ui p {
    font-size: 14px;
    color: #666;
    text-align: center;
    line-height: 1.6; }
  .title-ui__btn-add, .title-ui__btn-back {
    position: absolute;
    top: 12px;
    right: 32px;
    background-color: #364575;
    line-height: 32px;
    height: 32px;
    display: inline-block;
    color: #fff;
    text-align: center;
    font-size: 14px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    border: 0;
    width: 96px; }
    .title-ui__btn-add:last-child, .title-ui__btn-back:last-child {
      margin-bottom: 0; }
    .title-ui__btn-add:hover, .title-ui__btn-back:hover {
      text-decoration: none;
      background-color: #263052; }

.group-ui {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  padding: 32px;
  border: solid 1px #eee;
  margin-bottom: 24px;
  border-radius: 8px;
  box-sizing: border-box; }
  .group-ui:last-child {
    margin-bottom: 0; }

.login-panel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 320px;
  border-radius: 24px;
  padding: 32px;
  box-shadow: 0 4px 12px rgba(54, 69, 117, 0.3); }
  .login-panel__text {
    font-size: 16px;
    text-align: center;
    margin-bottom: 24px;
    color: #364575; }
  .login-panel__rows {
    margin-bottom: 16px; }
    .login-panel__rows:last-child {
      margin-bottom: 0; }
  .login-panel__form label {
    display: block;
    font-size: 14px;
    color: #999;
    margin-bottom: 4px; }
  .login-panel__form input {
    box-sizing: border-box;
    width: 100%;
    border: solid 1px #ccc;
    padding: 0 8px;
    line-height: 40px;
    height: 38px;
    transition: border-color 0.5s ease-in-out;
    color: #364575; }
    .login-panel__form input:focus {
      border-color: #364575; }
  .login-panel__btn-login {
    width: 100%;
    background-color: #364575;
    line-height: 48px;
    height: 48px;
    text-align: center;
    color: #fff;
    border: 0; }
    .login-panel__btn-login:hover {
      text-decoration: none;
      background-color: #263052; }

.login-footer {
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 8px;
  text-align: right;
  font-size: 14px;
  color: #999;
  border-top: 1px solid #eee; }

.left-panel {
  box-sizing: border-box;
  padding: 16px;
  width: 156px; }
  .left-panel__logo {
    margin-bottom: 16px;
    border-bottom: 1px solid #eee; }
    .left-panel__logo img {
      max-width: 100%;
      display: block;
      margin: 0; }
  .left-panel__profile, .left-panel__nav {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #eee; }
  .left-panel__name {
    color: #364575;
    font-size: 16px;
    margin-bottom: 8px;
    font-size: 14px; }
  .left-panel__btn {
    background-color: #364575;
    line-height: 32px;
    height: 32px;
    display: block;
    color: #fff;
    text-align: center;
    font-size: 14px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    margin-bottom: 8px; }
    .left-panel__btn:last-child {
      margin-bottom: 0; }
    .left-panel__btn:hover {
      text-decoration: none;
      background-color: #263052; }
    .left-panel__btn--logout {
      background-color: #f14280; }
      .left-panel__btn--logout:hover {
        background-color: #ed1360; }
  .left-panel__authority {
    border: solid 1px #364575;
    padding: 16px 8px 8px;
    position: relative; }
    .left-panel__authority > p {
      font-size: 14px;
      text-align: center;
      margin-bottom: 8px;
      color: #364575;
      background-color: #fff;
      position: absolute;
      top: -6px;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
      width: 64px; }
    .left-panel__authority > ul li {
      margin-bottom: 8px;
      font-size: 14px;
      color: #666; }
      .left-panel__authority > ul li:last-child {
        margin-bottom: 0; }

.change-psw {
  padding: 16px;
  box-sizing: border-box; }
  .change-psw__text {
    font-size: 16px;
    text-align: center;
    margin-bottom: 24px;
    color: #364575; }
  .change-psw__rows {
    margin-bottom: 16px; }
    .change-psw__rows:last-child {
      margin-bottom: 0; }
  .change-psw__form {
    width: 480px;
    margin: 0 auto; }
    .change-psw__form label {
      display: block;
      font-size: 14px;
      color: #999;
      margin-bottom: 4px; }
    .change-psw__form input {
      box-sizing: border-box;
      width: 100%;
      border: solid 1px #ccc;
      padding: 0 8px;
      line-height: 40px;
      height: 38px;
      transition: border-color 0.5s ease-in-out;
      color: #364575; }
      .change-psw__form input:focus {
        border-color: #364575; }
  .change-psw__btn-submit {
    width: 100%;
    background-color: #364575;
    line-height: 48px;
    height: 48px;
    text-align: center;
    color: #fff;
    border: 0; }
    .change-psw__btn-submit:hover {
      background-color: #263052; }

.logo-upload {
  padding: 16px; }
  .logo-upload__logo {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #eee; }
  .logo-upload__form {
    text-align: center; }
    .logo-upload__form form {
      font-size: 14px;
      color: #666; }
    .logo-upload__form input[type="file"] {
      font-size: 1px;
      display: inline-block; }
  .logo-upload__btn-upload {
    background-color: #364575;
    line-height: 32px;
    height: 32px;
    display: inline-block;
    color: #fff;
    text-align: center;
    font-size: 14px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    margin-bottom: 8px;
    border: 0;
    width: 96px; }
    .logo-upload__btn-upload:last-child {
      margin-bottom: 0; }
    .logo-upload__btn-upload:hover {
      text-decoration: none;
      background-color: #263052; }
  .logo-upload__list {
    padding: 0 32px; }
    .logo-upload__list table {
      width: 100%;
      margin: 0 auto; }
      .logo-upload__list table th {
        border: solid 1px #cfd1d5;
        border-bottom-width: 4px;
        padding: 20px 8px;
        color: #364575;
        font-size: 14px;
        background-color: #fff;
        line-height: 1.6;
        transition: all 0.5s ease-in-out; }
      .logo-upload__list table tr {
        background-color: #fefefe;
        transition: all 0.5s ease-in-out; }
        .logo-upload__list table tr:nth-child(odd) {
          background-color: #f9f9f9; }
        .logo-upload__list table tr:hover {
          background-color: #e1e5f4; }
      .logo-upload__list table td {
        border: solid 1px #cfd1d5;
        padding: 12px 8px;
        text-align: center;
        color: #444;
        font-size: 16px;
        line-height: 1.6;
        transition: all 0.2s ease-in-out;
        vertical-align: middle; }
        .logo-upload__list table td img {
          width: 120px; }

.account-list {
  padding: 16px; }
  .account-list__btn-add {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #364575;
    line-height: 32px;
    height: 32px;
    display: inline-block;
    color: #fff;
    text-align: center;
    font-size: 14px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    margin-bottom: 8px;
    border: 0;
    width: 96px; }
    .account-list__btn-add:last-child {
      margin-bottom: 0; }
    .account-list__btn-add:hover {
      text-decoration: none;
      background-color: #263052; }
  .account-list__list {
    padding: 0 32px; }
    .account-list__list table {
      width: 100%;
      margin: 0 auto; }
      .account-list__list table th {
        border: solid 1px #cfd1d5;
        border-bottom-width: 4px;
        padding: 20px 8px;
        color: #364575;
        font-size: 14px;
        line-height: 1.6;
        background-color: #fff;
        transition: all 0.5s ease-in-out; }
      .account-list__list table tr {
        background-color: #fefefe;
        transition: all 0.5s ease-in-out; }
        .account-list__list table tr:nth-child(odd) {
          background-color: #f9f9f9; }
        .account-list__list table tr:hover {
          background-color: #e1e5f4; }
      .account-list__list table td {
        border: solid 1px #cfd1d5;
        padding: 12px 8px;
        text-align: center;
        color: #444;
        font-size: 14px;
        transition: all 0.2s ease-in-out;
        vertical-align: middle;
        line-height: 1.6; }
        .account-list__list table td a {
          background-color: #364575;
          line-height: 32px;
          height: 32px;
          display: inline-block;
          color: #fff;
          text-align: center;
          font-size: 14px;
          text-decoration: none;
          transition: all 0.3s ease-in-out;
          margin-bottom: 8px;
          border: 0;
          width: 64px; }
          .account-list__list table td a:last-child {
            margin-bottom: 0; }
          .account-list__list table td a:hover {
            text-decoration: none;
            background-color: #263052; }
        .account-list__list table td .status-disable,
        .account-list__list table td .status-enable {
          width: 64px;
          font-size: 14px;
          display: inline-block;
          border: solid 1px transparent;
          height: 32px;
          line-height: 32px; }
        .account-list__list table td .status-enable {
          border-color: #6cad05;
          color: #6cad05; }
        .account-list__list table td .status-disable {
          border-color: #999;
          color: #666; }

.account-edit {
  padding: 16px; }
  .account-edit__detail {
    width: 660px;
    margin: 0 auto; }
  .account-edit__rows {
    margin-bottom: 16px; }
    .account-edit__rows:last-child {
      margin-bottom: 0; }
    .account-edit__rows label {
      display: block;
      font-size: 14px;
      color: #444;
      margin-bottom: 4px;
      line-height: 19px; }
      .account-edit__rows label.admin {
        color: #f14280; }
    .account-edit__rows input[type="text"],
    .account-edit__rows input[type="password"] {
      box-sizing: border-box;
      width: 100%;
      border: solid 1px #ccc;
      padding: 0 8px;
      line-height: 40px;
      height: 38px;
      transition: border-color 0.5s ease-in-out;
      color: #364575;
      font-weight: 500; }
      .account-edit__rows input[type="text"]:focus,
      .account-edit__rows input[type="password"]:focus {
        border-color: #364575;
        background-color: #eef0f7; }
    .account-edit__rows input[type="checkbox"] {
      margin-right: 4px;
      display: inline-block;
      position: relative;
      top: -1px; }
    .account-edit__rows > ul li {
      margin-bottom: 8px;
      font-size: 14px;
      color: #666; }
      .account-edit__rows > ul li:last-child {
        margin-bottom: 0; }
  .account-edit__text {
    font-size: 14px;
    color: #364575;
    text-align: center;
    padding-bottom: 16px;
    border-bottom: 1px dashed #eee;
    margin-bottom: 16px;
    font-weight: 900; }
  .account-edit__account {
    font-size: 14px;
    color: #364575; }
    .account-edit__account strong {
      font-weight: 900; }
  .account-edit__btn-back {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #364575;
    line-height: 32px;
    height: 32px;
    display: inline-block;
    color: #fff;
    text-align: center;
    font-size: 14px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    margin-bottom: 8px;
    border: 0;
    width: 96px; }
    .account-edit__btn-back:last-child {
      margin-bottom: 0; }
    .account-edit__btn-back:hover {
      text-decoration: none;
      background-color: #263052; }
  .account-edit__btn-submit {
    width: 100%;
    background-color: #364575;
    line-height: 40px;
    height: 40px;
    display: inline-block;
    color: #fff;
    text-align: center;
    font-size: 14px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    margin-bottom: 8px;
    border: 0; }
    .account-edit__btn-submit:last-child {
      margin-bottom: 0; }
    .account-edit__btn-submit:hover {
      text-decoration: none;
      background-color: #263052; }
  .account-edit__mesg {
    padding-top: 8px;
    color: #f14280;
    font-size: 14px; }

.measure-data {
  padding: 16px; }
  .measure-data__rows {
    margin-bottom: 16px; }
    .measure-data__rows:last-child {
      margin-bottom: 0; }
  .measure-data__filter {
    width: 640px;
    margin: 0 auto; }
    .measure-data__filter label {
      display: inline-block;
      font-size: 14px;
      color: #444;
      margin-bottom: 4px;
      line-height: 19px; }
      .measure-data__filter label.admin {
        color: #f14280; }
    .measure-data__filter input[type="date"] {
      box-sizing: border-box;
      width: 230px;
      border: solid 1px #ccc;
      padding: 0 8px;
      line-height: 40px;
      height: 38px;
      transition: border-color 0.5s ease-in-out;
      color: #364575;
      font-weight: 500; }
      .measure-data__filter input[type="date"]:focus {
        border-color: #364575;
        background-color: #eef0f7; }
    .measure-data__filter input[type="text"] {
      box-sizing: border-box;
      width: 280px;
      border: solid 1px #ccc;
      padding: 0 8px;
      line-height: 40px;
      height: 38px;
      transition: border-color 0.5s ease-in-out;
      color: #364575;
      font-weight: 500; }
      .measure-data__filter input[type="text"]:focus {
        border-color: #364575;
        background-color: #eef0f7; }
    .measure-data__filter input[type="checkbox"] {
      margin-right: 4px;
      display: inline-block;
      position: relative;
      top: -1px; }
  .measure-data__btn-search {
    background-color: #364575;
    line-height: 40px;
    height: 40px;
    display: inline-block;
    color: #fff;
    text-align: center;
    font-size: 14px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    margin-bottom: 8px;
    border: 0;
    width: 140px;
    margin-right: 16px; }
    .measure-data__btn-search:last-child {
      margin-bottom: 0; }
    .measure-data__btn-search:hover {
      text-decoration: none;
      background-color: #263052; }
  .measure-data__list {
    padding: 16px 32px;
    border-top: 1px solid #eee; }
    .measure-data__list table {
      width: 100%;
      margin: 0 auto; }
      .measure-data__list table th {
        border: solid 1px #cfd1d5;
        border-bottom-width: 4px;
        padding: 20px 8px;
        color: #364575;
        font-size: 14px;
        background-color: #fff;
        transition: all 0.5s ease-in-out;
        vertical-align: middle;
        line-height: 1.6; }
      .measure-data__list table tr {
        background-color: #fefefe;
        transition: all 0.5s ease-in-out; }
        .measure-data__list table tr:nth-child(odd) {
          background-color: #f9f9f9; }
        .measure-data__list table tr:hover {
          background-color: #e1e5f4; }
      .measure-data__list table td {
        border: solid 1px #cfd1d5;
        padding: 12px 8px;
        text-align: center;
        color: #444;
        font-size: 14px;
        transition: all 0.2s ease-in-out;
        vertical-align: middle;
        line-height: 1.6; }
        .measure-data__list table td a {
          background-color: #364575;
          line-height: 32px;
          height: 32px;
          display: block;
          color: #fff;
          text-align: center;
          font-size: 14px;
          text-decoration: none;
          transition: all 0.3s ease-in-out;
          margin-bottom: 8px;
          border: 0;
          width: 96px; }
          .measure-data__list table td a:last-child {
            margin-bottom: 0; }
          .measure-data__list table td a:hover {
            text-decoration: none;
            background-color: #263052; }
        .measure-data__list table td .status-disable,
        .measure-data__list table td .status-enable {
          width: 64px;
          font-size: 14px;
          display: inline-block;
          border: solid 1px transparent;
          height: 32px;
          line-height: 32px; }
        .measure-data__list table td .status-enable {
          border-color: #6cad05;
          color: #6cad05; }
        .measure-data__list table td .status-disable {
          border-color: #999;
          color: #666; }
        .measure-data__list table td table {
          background-color: #fff; }
        .measure-data__list table td tr {
          background-color: transparent; }
          .measure-data__list table td tr:hover {
            background-color: transparent; }
        .measure-data__list table td td {
          padding: 0;
          background-color: transparent;
          border: 0; }
